import React from 'react';

/**
 * Images/SVG
 */
import Background from '../images/graphicDesignH.png';
import GraphicDesignOne from '../images/graphicDesign.jpg';
import GraphicDesignTwo from '../images/graphicDesign2.jpg';

/**
 * Components
 */
import SEO from '../components/seo/seo';
import Layout from '../components/layout/layout';
import Banner from '../components/default-banner/default-banner';
import DynamicTextBlock from '../components/dynamic-text-block/dynamic-text-block';

export default () => {

  const innerBannerData = {
    backgroundImg: { backgroundImage: `url(${Background}` },
    mainTitle: 'Graphic Design Agency London',
    mainText: ''
  };

  const dataBlockOne = {
    left: true,
    img: <img alt="animation" src={GraphicDesignOne}/>,
    title: 'Looking For A Graphic Design Agency London? Here Is What You Should Know',
    textPieceOne: 'It has been seen over the past few years that graphics help to deliver your intended message in the best possible way. These designs not only attract more audience attention but also engages people for longer durations. That is why graphics get incorporated everywhere nowadays, from your brand logo to your digital campaigns. However, you must understand that not every graphic design piece is the same. Thus, you must take the assistance of a graphic design agency London if you wish these visuals to be beneficial for your business.',
    list: [],
    listAfterLine: '',
    textPieceTwo: 'Here is how the experts of Radium can help you out with the work.'
  };

  const dataBlockTwo = {
    left: false,
    img: <img alt="animation" src={GraphicDesignTwo}/>,
    title: 'Delivering Message',
    textPieceOne: 'Incorporating graphics doesn’t mean putting on random images to make the plain text better. Instead, it is a method to deliver your brand’s message with or without plain text. Even if there is some text, the visuals should speak for themselves. Moreover, it should be interesting enough to keep the audience’s eyes stuck to the image.',
    list: [],
    listAfterLine: '',
    textPieceTwo: 'This is where our graphic design agency London experts can assist you. They design the visual pieces in a way that your audience can engage with it, and your business can benefit from it. All this work gets done while keeping your brand reputation and tone in mind. Hence, you get the most incredible results.'
  };

  const dataBlockThree = {
    left: true,
    img: '',
    title: 'Complete Graphics Solution',
    textPieceOne: 'As stated earlier, graphics are nowadays used everywhere, such as:',
    list: [{title: '', content: 'Your brand’s logo'}, {title: '', content: 'Digital campaigns or advertisements'}, {title: '', content: 'Physical marketing material'}, {title: '', content: 'Brochures'}],
    listAfterLine: 'And the list goes on…',
    textPieceTwo: 'Radium experts offer you a complete graphics solution for all your business and marketing requirements. Thus, you need not worry about getting help from different places for your distinct needs. Our designers will ensure you get exactly what you wish for, in just one spot.'
  };

  const dataBlockFour = {
    left: true,
    title: '',
    textPieceOne: 'It has been seen over the past few years that graphics help to deliver your intended message in the best possible way. These designs not only attract more audience attention but also engages people for longer durations. That is why graphics get incorporated everywhere nowadays, from your brand logo to your digital campaigns. However, you must understand that not every graphic design piece is the same. Thus, you must take the assistance of a graphic design agency London if you wish these visuals to be beneficial for your business.',
    list: [],
    listAfterLine: '',
    textPieceTwo: 'Here is how the experts of Radium can help you out with the work.'
  };

  return (
    <Layout>
      <SEO 
        title="About" 
        keywords={[ `digital`, `agency`, `technology`, `Radium`, `About` ]} 
      />
      <Banner {...innerBannerData}/>

      <DynamicTextBlock {...dataBlockOne}/>
      <DynamicTextBlock {...dataBlockTwo}/>
      <DynamicTextBlock {...dataBlockThree}/>
      <DynamicTextBlock {...dataBlockFour}/>

    </Layout>
  )
};
