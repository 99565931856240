import React from 'react';
import Styles from './dynamic-list.module.scss'

const DynamicList = (
  props
) => {

  return (
    <>
      <div className={Styles.listWrapper}>
        <ul className={Styles.list}>
          {props.list.map((value, index) => 
            value.title ? 
              <li key={index}><strong>{value.title}</strong>{value.content}</li> :
              <li key={index}>{value.content}</li>
          )}
        </ul>
        {props.listAfterLine ? <p>{props.listAfterLine}</p> : ''}
      </div>
    </>
  )
}


export default DynamicList;