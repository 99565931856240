import React from 'react';
import Styles from './dynamic-text-block.module.scss';

import DynamicList from '../dynamic-list/dynamic-list';

const DynamicTextBlock = (
  props
) => {

  return (
    <>
      {console.log(props)}
      <div className={`container ${Styles.container}`}>
        {props.title ? <h2 className={Styles.title}>{props.title}</h2> : '' }
        <div className={ props.left ? Styles.wrapper : `${Styles.wrapper} ${Styles.reverse}`}>
          {props.img}
          {props.textPieceOne ? 
            <p className={Styles.textPieceOne}>{props.textPieceOne}</p> : '' 
          }
        </div>
        {props.list.length ? 
          <DynamicList list={props.list} listAfterLine={props.listAfterLine}/> : ''
        }
        {props.textPieceTwo ? 
          <p className={Styles.textPieceTwo}>{props.textPieceTwo}</p> : '' 
        }
      </div>
    </>
  )
}


export default DynamicTextBlock;